<template>
  <div class="caseShare">
        <div class="head">
            <img src="../assets/img/share_bg.png" alt="">
            <div class="shade_share"></div>
            <div class="tit">服务<span>120</span>所高校 <span>150</span>万大学生</div>
        </div>
        <div class="center">
              <div class="tit1">重点案例解析</div>
              <div class="tit2">排名不分先后</div>
              <div class="school-box">
                <a href="https://reader.gmw.cn/2020-09/25/content_34222839.htm" target="_blank" class="school-item">
                        <div class="school-item-left">  
                              <img src="../assets/schoolLogo/bjdewgy.jpg" alt="">
                              <div class="schoolName">北京第二外国语大学</div>
                        </div>
                        <div class="el-icon-arrow-right arrow"></div>
                </a>
                <a href="https://mp.weixin.qq.com/s/3Bg3ofOoUOd4JKGwk8VcWQ" target="_blank" class="school-item">
                        <div class="school-item-left">  
                              <img src="../assets/img/Icon.png" alt="">
                              <div class="schoolName">团省委社会实践平台</div>
                        </div>
                        <div class="el-icon-arrow-right arrow"></div>
                </a>
                <a href="https://mp.weixin.qq.com/s/xHoHsrNIDmm5PBxIHQtAIA" target="_blank" class="school-item">
                        <div class="school-item-left">  
                              <img src="../assets/schoolLogo/whdx.jpg" alt="">
                              <div class="schoolName">武汉大学</div>
                        </div>
                        <div class="el-icon-arrow-right arrow"></div>
                </a>
                <a href="https://mp.weixin.qq.com/s/77wZsmWF5SUjKB2Un3RgWQ" target="_blank" class="school-item">
                        <div class="school-item-left">  
                              <img src="../assets/schoolLogo/xadz.jpg" alt="">
                              <div class="schoolName">西安电子科技大学</div>
                        </div>
                        <div class="el-icon-arrow-right arrow"></div>
                </a>
                <a href="https://www.univteam.com/#/detailss" target="_blank" class="school-item">
                        <div class="school-item-left">  
                              <img src="../assets/schoolLogo/xbdx.jpg" alt="">
                              <div class="schoolName">西北大学</div>
                        </div>
                        <div class="el-icon-arrow-right arrow"></div>
                </a>
                <a href="https://www.univteam.com/#/details" target="_blank" class="school-item">
                        <div class="school-item-left">  
                              <img src="../assets/schoolLogo/zgrm.jpg" alt="">
                              <div class="schoolName">中国人民大学</div>
                        </div>
                        <div class="el-icon-arrow-right arrow"></div>
                </a>
                <div class="school-item" @click="putAlert()">
                        <div class="school-item-left">  
                              <img src="../assets/schoolLogo/bjhk.jpg" alt="">
                              <div class="schoolName">北京航空航天大学</div>
                        </div>
                        <div class="el-icon-arrow-right arrow"></div>
                </div>
                <div class="school-item" @click="putAlert()">
                        <div class="school-item-left">  
                              <img src="../assets/schoolLogo/zgzfdx.jpg" alt="">
                              <div class="schoolName">中国政法大学</div>
                        </div>
                        <div class="el-icon-arrow-right arrow"></div>
                </div>
                <div class="school-item" @click="putAlert()">
                        <div class="school-item-left">  
                              <img src="../assets/schoolLogo/cqdx.jpg" alt="">
                              <div class="schoolName">重庆大学</div>
                        </div>
                        <div class="el-icon-arrow-right arrow"></div>
                </div>
              </div>
        </div>
        <div class="bottom">
          <div class="bottom-head">
              <img src="../assets/img/share_bg2.png" alt="">
              <div class="shade2"></div>
          </div>
           <div class="bottom-content"> 
                <div class="tit1">
                  全国合作院校
                </div>
                <div class="tit2">排名不分先后</div>
                <div class="content">
                     <div><img src="../assets/schoolLogo/zgrm.jpg" alt=""><p>中国人民大学</p></div>
                     <div><img src="../assets/schoolLogo/qhdx.jpg" alt=""><p>清华大学</p></div>
                     <div><img src="../assets/schoolLogo/bjhk.jpg" alt=""><p>北京航空航天大学</p></div>
                     <div><img src="../assets/schoolLogo/zgny.jpg" alt=""><p>中国农业大学</p></div>
                     <div><img src="../assets/schoolLogo/bjlg.jpg" alt=""><p>北京理工大学</p></div>
                     <div><img src="../assets/schoolLogo/zymz.jpg" alt=""><p>中央民族大学</p></div>
                     <div><img src="../assets/schoolLogo/fddx.jpg" alt=""><p>复旦大学</p></div>
                      <div><img src="../assets/schoolLogo/shjt.jpg" alt=""><p>上海交通大学</p></div>
                      <div><img src="../assets/schoolLogo/tjdx.jpg" alt=""><p>同济大学</p></div> 
                      <div><img src="../assets/schoolLogo/whdx.jpg" alt=""><p>武汉大学</p></div>  
                      <div><img src="../assets/schoolLogo/hzkj.jpg" alt=""><p>华中科技大学</p></div>  
                      <div><img src="../assets/schoolLogo/cqdx.jpg" alt=""><p>重庆大学</p></div>  
                      <div><img src="../assets/schoolLogo/zndx.jpg" alt=""><p>中南大学</p></div>  
                       <div><img src="../assets/schoolLogo/hndx.jpg" alt=""><p>湖南大学</p></div>
                       <div><img src="../assets/schoolLogo/xbgy.jpg" alt=""><p>西北工业大学</p></div>
                       <div><img src="../assets/schoolLogo/jldx.jpg" alt=""><p>吉林大学</p></div>
                       <div><img src="../assets/schoolLogo/bjgy.jpg" alt=""><p>北京工业大学</p></div> 
                       <div><img src="../assets/schoolLogo/bjjt.jpg" alt=""><p>北京交通大学</p></div>
                       <div><img src="../assets/schoolLogo/bjly.jpg" alt=""><p>北京林业大学</p></div>
                       <div><img src="../assets/schoolLogo/bjty.jpg" alt=""><p>北京体育大学</p></div>
                       <div><img src="../assets/schoolLogo/bjwgy.jpg" alt=""><p>北京外国语大学</p></div>
                      <div><img src="../assets/schoolLogo/hbdl.jpg" alt=""><p>华北电力大学</p></div>
                      <div><img src="../assets/schoolLogo/sdsf.jpg" alt=""><p>首都师范大学</p></div>
                      <div><img src="../assets/schoolLogo/xadz.jpg" alt=""><p>西安电子科技大学</p></div>
                     <div><img src="../assets/schoolLogo/xbdx.jpg" alt=""><p>西北大学</p></div>
                     <div><img src="../assets/schoolLogo/rmgadx.jpg" alt=""><p>中国人民公安大学</p></div>
                     <div><img src="../assets/schoolLogo/zgzfdx.jpg" alt=""><p>中国政法大学</p></div>
                     <div><img src="../assets/schoolLogo/tjyk.jpg" alt=""><p>天津医科大学</p></div>
                     <div><img src="../assets/schoolLogo/xncj.jpg" alt=""><p>西南财经大学</p></div>
                     <div><img src="../assets/schoolLogo/zgyyxy.jpg" alt=""><p>中国音乐学院</p></div>
                     <div><img src="../assets/schoolLogo/zgkxy.jpg" alt=""><p>中国科学院大学</p></div>
                     <div><img src="../assets/schoolLogo/hzny.jpg" alt=""><p>华中农业大学</p></div>
                     <div><img src="../assets/schoolLogo/hzsf.jpg" alt=""><p>华中师范大学</p></div>
                     <div><img src="../assets/schoolLogo/whlg.jpg" alt=""><p>武汉理工大学</p></div>
                     <div><img src="../assets/schoolLogo/dyjm.jpg" alt=""><p>对外经贸大学</p></div>
                     <div><img src="../assets/schoolLogo/zycj.jpg" alt=""><p>中央财经大学</p></div>
                     <div><img src="../assets/schoolLogo/hnlg.jpg" alt=""><p>河南理工大学</p></div>
                    <div><img src="../assets/schoolLogo/tylg.jpg" alt=""><p>太原理工大学</p></div>
                    <div><img src="../assets/schoolLogo/thzyy.jpg" alt=""><p>天津中医药大学</p></div>
                    <div><img src="../assets/schoolLogo/bjjtwh.jpg" alt=""><p>北京交通大学威海校区（同北京交通大学）</p></div>
                    <div><img src="../assets/schoolLogo/bjdewgy.jpg" alt=""><p>北京第二外国语学院</p></div>
                    <div><img src="../assets/schoolLogo/bjjcxy.jpg" alt=""><p>北京警察学院</p></div>
                    <div><img src="../assets/schoolLogo/bjwdxy.jpg" alt=""><p>北京舞蹈学院</p></div>
                    <div><img src="../assets/schoolLogo/cdfz.jpg" alt=""><p>成都纺织高等专科学校</p></div>
                    <div><img src="../assets/schoolLogo/dlwgy.jpg" alt=""><p>大连外国语大学</p></div> 
                    <div><img src="../assets/schoolLogo/dbcj.jpg" alt=""><p>东北财经大学</p></div>  
                    <div><img src="../assets/schoolLogo/jxsf.jpg" alt=""><p>江西师范大学</p></div>  
                    <div><img src="../assets/schoolLogo/sdyk.jpg" alt=""><p>首都医科大学</p></div>  
                    <div><img src="../assets/schoolLogo/zgld.jpg" alt=""><p>中国劳动关系学院</p></div> 
                    <div><img src="../assets/schoolLogo/zgshk.jpg" alt=""><p>中国社会科学院大学</p></div>  
                    <div><img src="../assets/schoolLogo/xnyk.jpg" alt=""><p>西南医科大学</p></div> 
                </div>
           </div>
        </div>
  </div>
</template>

<script>
export default {
  name: 'caseShare',
  data() {
      return {
        schoolList:[{name:'重庆大学',img:require('@/assets/img/schoolLogo.png')},{name:'重庆大学',img:require('@/assets/img/schoolLogo.png')},{name:'重庆大学',img:require('@/assets/img/schoolLogo.png')},{name:'重庆大学',img:require('@/assets/img/schoolLogo.png')},{name:'重庆大学',img:require('@/assets/img/schoolLogo.png')},{name:'重庆大学',img:require('@/assets/img/schoolLogo.png')},{name:'重庆大学',img:require('@/assets/img/schoolLogo.png')},{name:'重庆大学',img:require('@/assets/img/schoolLogo.png')},{name:'重庆大学',img:require('@/assets/img/schoolLogo.png')},]
        ,schoolMore:[{name:'北京航空航天大学',img:require('@/assets/img/schoolLogo.png')},{name:'北京航空航天大学',img:require('@/assets/img/schoolLogo.png')},{name:'北京航空航天大学',img:require('@/assets/img/schoolLogo.png')},{name:'北京航空航天大学',img:require('@/assets/img/schoolLogo.png')},{name:'北京航空航天大学',img:require('@/assets/img/schoolLogo.png')},{name:'北京航空航天大学',img:require('@/assets/img/schoolLogo.png')},{name:'北京航空航天大学',img:require('@/assets/img/schoolLogo.png')},{name:'北京航空航天大学',img:require('@/assets/img/schoolLogo.png')},{name:'北京航空航天大学',img:require('@/assets/img/schoolLogo.png')},{name:'北京航空航天大学',img:require('@/assets/img/schoolLogo.png')},{name:'北京航空航天大学',img:require('@/assets/img/schoolLogo.png')},{name:'北京航空航天大学',img:require('@/assets/img/schoolLogo.png')},{name:'北京航空航天大学',img:require('@/assets/img/schoolLogo.png')}]
      };
    },
    methods: {
      putAlert(){
        this.$message('敬请期待~');
      }
    }
}
</script>
<style lang="scss" scoped>
a{
  text-decoration: none;
}
.head{
  position: relative;
  img{
    width: 100%;
    display:block;
  }
  .shade_share{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(77,120,147,.6);
    z-index: 5;
  }
  .tit{
    font-weight: bold;
    font-size: 50px;
    color: #fff;
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    margin-top: -25px;
    z-index: 6;
    span{
      color: #00EBFF;
      border-bottom: 3px solid #00EBFF;
      margin: 0 5px;
    }
  }
}
.center{
    padding:0 350px;
  padding-top: 120px;
  background: #fff;
  .tit1{
    font-size: 36px;
    font-weight: bold;
    color: #20A8D9;
  }
  .tit2{
    font-size: 18px;
    color: #666666;
    margin-top: 15px;
    margin-bottom: 60px;
  }
  .school-box{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 65px;
    .school-item{
      width: 340px;
      height: 80px;
      display: flex;
      align-items: center;
      border-radius: 5px;
      background: #EEF9FC;
      padding: 0 20px;
      justify-content: space-between;
      margin-right: 23px;
      cursor: pointer;
      margin-bottom: 20px;
      .school-item-left{
        display: flex;
        align-items: center;
      }
      img{
        border-radius: 50%;
        width: 50px;
      }
      .schoolName{
        font-size: 21px;
        color: #333333;
        margin-left: 22px;
        text-align: left;
      }
      .arrow{
        font-size: 23px;
        color: #7C829D;
      }
    }
    .school-item:nth-child(3n+3)
      {
         margin-right: 0;
      }
  }
}
.bottom{
  background: #fff;
  overflow: hidden;
  position: relative;
  padding-top: 100px;
  .bottom-head{
    position: absolute;
    top: 100px;
  }
  img{
    width: 100%;
  }
  .shade2{
    background: linear-gradient(to right, #8BA2B3 , #2A526A);
    opacity: .36;
    position: absolute;
    top: 0px;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
.bottom-content{
  width: 1200px;
  position: relative;
  z-index: 2;
  margin: 0 auto;
  .tit1{
    font-size: 36px;
    font-weight: bold;
    margin-top: 120px;
     color: #fff;
  }
  .tit2{
    margin-top: 15px;
    font-size: 18px;
    color: #fff;
    margin-bottom: 60px;
  }
  .content{
    display: flex;
    background: #fff;
    width: 100%;
    padding: 60px 108px;
    padding-bottom: 10px;
    box-sizing: border-box;
    flex-wrap: wrap;
 justify-content: flex-start;
    align-items: center;
    box-shadow: 0 3px 10px rgba(0,0,0,.1);
    margin-bottom: 120px;
    div{
      width: 140px;
      margin-bottom: 50px;
      margin-right: 66px;
      img{
        width: 100px;
        height: 100px;
      }
      p{
        font-size: 16px;
        color: #333333;
        margin-top: 10px;
      }
    }
    div:nth-child(5n+5){
      margin-right: 0;
    }
  }
}
</style>

